import { useState, useEffect, useCallback, useMemo, useLayoutEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import type { Option } from 'shared/types'
import { useQuery } from 'context/QueryProvider'
import { useI18n } from 'context/I18nProvider'
import { API_FILTERS } from 'constants/api-v2'

export type Value = Option | string | number | boolean | null | undefined
export interface FilterValues {
  [key: string]: Value | Value[]
  q?: string
}

const getValue = (value: Value | null | undefined): string => {
  if (value === null || value === undefined) {
    return ''
  }

  if (typeof value === 'object' && 'value' in value) {
    return value.value ?? ''
  }

  return value.toString()
}

const updateSearchParams = (searchParams: URLSearchParams, values: FilterValues) => {
  Object.entries(values).forEach(([key, value]) => {
    searchParams.delete(key)
    searchParams.delete(`${key}[]`)

    if (Array.isArray(value)) {
      value.forEach(val => {
        searchParams.append(`${key}[]`, getValue(val).toString())
      })
    } else {
      searchParams.set(key, getValue(value).toString())
    }
  })
}

const valuesToURLSearchParams = (initialValues: FilterValues, updateHistory: boolean) => {
  if (!updateHistory) return undefined

  const newParams = new URLSearchParams()

  updateSearchParams(newParams, initialValues ?? {})

  return newParams
}

const URLSearchParamsToValues = (searchParams: URLSearchParams, initialValues: FilterValues) => {
  const valuesFromURL: FilterValues = {}
  searchParams.forEach((value, key) => {
    if (key.endsWith('[]')) {
      const actualKey = key.slice(0, -2)

      if (!valuesFromURL[actualKey]) {
        valuesFromURL[actualKey] = []
      }

      (valuesFromURL[actualKey] as Value[]).push(value)
    } else {
      valuesFromURL[key] = value
    }
  })

  return { ...initialValues, ...valuesFromURL }
}

const useFilters = (
  prefix: string,
  updateHistory: boolean = true,
  initialValues: FilterValues = {},
  resetPagination: Function = () => {},
) => {
  const { client } = useQuery()
  const { i18name } = useI18n()
  // eslint-disable-next-line max-len
  const [searchParams, setSearchParams] = useSearchParams()
  const [values, setValues] = useState<{ [prefix: string]: FilterValues }>()
  const [isInitialized, setInitialized] = useState(false)

  useLayoutEffect(() => {
    setSearchParams(valuesToURLSearchParams(initialValues, updateHistory))
    setValues({
      [prefix]: URLSearchParamsToValues(searchParams, initialValues),
    })
  }, [])

  const fetchLabels = useCallback(async (sp?: URLSearchParams) => {
    const params = sp?.toString() ?? searchParams.toString()
    if (client) {
      const response = await client.get(`${API_FILTERS}?${params}`)
      const updatedValues = Object.keys(response).reduce<FilterValues>((accumulated, key) => ({
        ...accumulated,
        [key]: response[key].map((option: any) => ({
          label: option.name ?? i18name(option) ?? option.fullName,
          value: option.id,
        })),
      }), {})
      setValues(prev => ({ ...prev, [prefix]: { ...(prev?.[prefix] ?? {}), ...updatedValues } }))
    }
  }, [client, searchParams, i18name, prefix])

  useEffect(() => {
    if (!isInitialized) {
      fetchLabels()

      if (updateHistory) {
        setSearchParams(searchParams, { replace: true })
      }

      setInitialized(true)
    }
  }, [isInitialized, fetchLabels, updateHistory, searchParams, setSearchParams, initialValues])

  const updateValue = useCallback((key: string | FilterValues, value?: Value | Value[]) => {
    if (typeof key === 'string') {
      setValues(prev => ({ ...prev, [prefix]: { ...(prev?.[prefix] ?? {}), [key]: value } }))
    } else {
      setValues(prev => ({ ...prev, [prefix]: { ...(prev?.[prefix] ?? {}), ...key } }))
    }

    if (updateHistory) {
      const newSearchParams = new URLSearchParams(searchParams)

      if (typeof key === 'string') {
        updateSearchParams(newSearchParams, { [key]: value })
      } else {
        updateSearchParams(newSearchParams, key)
      }

      if (updateHistory) {
        setSearchParams(newSearchParams, { replace: true })
      }
    }

    resetPagination()
  }, [updateHistory, searchParams, setSearchParams, prefix])

  const reset = useCallback((filters?: FilterValues) => {
    setValues({ [prefix]: filters ?? {} })

    if (updateHistory) {
      if (filters) {
        const newSearchParams = valuesToURLSearchParams(filters, updateHistory)
        setSearchParams(newSearchParams, { replace: true })
        fetchLabels(newSearchParams)
      } else {
        setSearchParams(valuesToURLSearchParams({}, updateHistory), { replace: true })
      }
    }

    resetPagination()
  }, [setSearchParams, initialValues, resetPagination, prefix, fetchLabels, updateHistory])

  const getDependencyUrl = useCallback((dependencyKey: string, outputKey: string) => {
    const dependentValues = values?.[prefix][dependencyKey] ?? []
    const params = new URLSearchParams()

    // optimize with updateSearchParams
    if (Array.isArray(dependentValues)) {
      dependentValues.forEach(val => {
        params.append(`${outputKey}[]`, getValue(val).toString())
      })
    } else {
      params.set(outputKey, getValue(dependentValues).toString())
    }

    return params.toString()
  }, [values, prefix])

  const url = useMemo(() => {
    const params = new URLSearchParams()

    updateSearchParams(params, values?.[prefix] ?? {})

    return params.toString()
  }, [values, prefix])

  const getCount = useCallback((keys: string[]) => (
    keys.reduce((acc, key) => {
      const item = values?.[prefix][key]

      if (Array.isArray(item)) {
        return acc + item.length
      }

      return acc + (Number(key in (values?.[prefix] ?? {})) || 0)
    }, 0)
  ), [values])

  return {
    values: values?.[prefix] ?? {},
    setValue: updateValue,
    url,
    reset,
    getDependencyUrl,
    getCount,
    isInitialized,
  }
}

export default useFilters
