import * as React from 'react'
import type { InputProps, InputRef } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import debounce from 'lodash/debounce'
import { useI18n } from 'context/I18nProvider'
import Input from 'components/UI/Input'

type SearchInputProps = Omit<InputProps, 'onChange'> & {
  onChange: (txt: string) => void
  withSearchIcon?: boolean
}

const SearchInput = React.forwardRef<InputRef, SearchInputProps>((props, ref) => {
  const { onChange, value, withSearchIcon = true, ...rest } = props
  const valueTyped = typeof value === 'string' ? value : ''
  const [internalValue, setInternalValue] = React.useState<string>(valueTyped)
  const { i18n } = useI18n()

  React.useEffect(() => {
    setInternalValue(valueTyped)
  }, [value])

  const debouncedSearch = React.useCallback(
    debounce((text: string) => {
      onChange?.(text)
    }, 300),
    [],
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setInternalValue(newValue)
    debouncedSearch(newValue)
  }

  return (
    <Input
      ref={ref}
      value={internalValue}
      addonBefore={withSearchIcon && <SearchOutlined className="text-lg" />}
      onChange={handleChange}
      placeholder={i18n('label.searchplaceholder')}
      allowClear
      outline={false}
      {...rest}
    />
  )
})
export default SearchInput
