import * as React from 'react'
import { Link, useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { notification, Progress } from 'antd'
import { CaretDownOutlined, GlobalOutlined, LogoutOutlined, MenuOutlined } from '@ant-design/icons'
import useSWR from 'hooks/swr'
import { useMercure } from 'hooks/mercure'
import { useNotification } from 'context/NotificationProvider'
import { useQuery } from 'context/QueryProvider'
import { useAuth } from 'context/AuthProvider'
import { useI18n } from 'context/I18nProvider'
import { useProfile } from 'context/ProfileProvider'
import { useSidebar } from 'context/SidebarProvider'
import Button from 'components/UI/Button'
import Dropdown from 'components/UI/Dropdown'
import HseSwitcher from 'components/HseSwitcher'
import Notification from 'components/Notification'
import NotificationHeader from 'components/Notification/Header'
import ReminderIcon from 'components/Reminders/Icon'
import Tooltip from 'components/UI/Tooltip'
import UserLabel from 'components/UserLabel'
import { API_URL_SSO_LOGOUT, API_URL_PROFILE_SWITCH } from 'constants/api'
import { API_REMINDERS_COUNT } from 'constants/api-v2'

function Header() {
  const settings = useRouteLoaderData('root')
  const navigate = useNavigate()
  const { profile, profiles, hasPermission } = useProfile()
  const { pathname, search } = useLocation()
  const { logout, refreshToken, user } = useAuth()
  const { i18n, locale: lang, setLocale, i18name } = useI18n()
  const { client } = useQuery()
  const { notifyError } = useNotification()
  const { subscribe } = useMercure()
  const { toggleNav, wide } = useSidebar()
  const [reminders, setReminders] = React.useState(null)
  const [message, setMessage] = React.useState()
  const [api, contextHolder] = notification.useNotification()

  React.useEffect(() => {
    const channels = [
      '/users',
      `/users/${user.id}`,
      `/profiles/${profile.id}`,
    ]

    const topics = [
      '/api/2.0/messages',
      '/api/2.0/reminders',
      '/notifications',
    ]

    const messageEventSource = subscribe(channels, topics, ({ data }) => {
      switch (data.type) {
        case 'new_message':
          setMessage({ type: data.notificationType ?? 'warning', content: i18n(data.label, data.labelParams ?? []) })
          break
        case 'reminders_count_update':
          setReminders(Number(data.count))
          break
        case 'retroactive_notifications': {
          const description = (
            <>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
              {data.percent !== null && <Progress percent={data.percent} />}
            </>
          )

          api[data.notificationType]({
            key: data.key,
            message: data.message,
            description,
            duration: 0,
            placement: 'bottomRight',
          })
          break
        }
        default:
          break
      }
    })

    return () => messageEventSource?.close()
  }, [i18n, subscribe, user.id, profile.id])

  const withReminders = hasPermission('SESSION_CAN_VIEW_TASK')

  useSWR(withReminders ? `${API_REMINDERS_COUNT}?done=false` : null, {
    onSuccess: data => setReminders(Number(data.count)),
  })

  const handleProfileClick = val => {
    client.post(API_URL_PROFILE_SWITCH.replace(':id', val))
      .then(response => {
        refreshToken(response.token)
        window.location.reload(false)
      })
      .catch(e => notifyError(e))
  }

  const handleSwitchLocale = val => {
    window.localStorage.setItem('locale', val)
    client.updateHeaders()
    setLocale(val)
    return navigate(`${pathname}${search}`.replace(lang, val))
  }

  const handleLogout = () => {
    logout()
    if (settings.ssoEnabled) {
      window.location.replace(API_URL_SSO_LOGOUT)
    } else {
      window.location.href = `/${lang}/login`
    }
  }

  const menuClasses = 'flex items-center cursor-pointer text-base px-4'

  if (!user) return null

  return (
    <div className="static right-2 flex justify-end text-white px-0 py-5" id="top-bar">
      {contextHolder}
      <Tooltip title={i18n('sidebar.open')} placement="right">
        <Button
          type="link"
          onClick={toggleNav}
          className={`
            text-gray-400 !hover:text-gray-400
            -top-[8px] ml-4
            border-solid border-gray-300 leading-[2.2]
            ${wide ? 'invisible' : 'visible'}
          `}
          icon={<MenuOutlined />}
        />
      </Tooltip>
      <div className="grow flex justify-center">
        {Boolean(message) && (
          <Notification message={message.content} type={message.type} afterClose={() => setMessage(null)} />
        )}
      </div>
      <div className="flex border-solid border-0 border-r-2">
        {(profile.type === 'super-admin') && (
          <div className={`${menuClasses}`}>
            <NotificationHeader />
          </div>
        )}
        {withReminders ? (
          <div className={`${menuClasses}`}>
            <Link to={`reminders/${user.id}/dates`}>
              <ReminderIcon
                count={reminders}
                className="text-white text-2xl"
                badgeClassName="w-[24px]"
              />
            </Link>
          </div>
        ) : null}
      </div>
      <Dropdown
        trigger={['hover']}
        items={(settings.locales || [])
          .filter(l => l !== lang)
          .map(l => ({
            key: l,
            label: i18n(`locale.${l}`),
            onClick: () => handleSwitchLocale(l),
          }))}
      >
        <div className="flex items-center cursor-pointer mx-6">
          <GlobalOutlined className="flex items-center text-xl mr-4" />
          {i18n(`locale.${lang}`)}
          <CaretDownOutlined className="text-sm ml-2" />
        </div>
      </Dropdown>
      {profiles.length > 1 ? (
        <Dropdown
          trigger={['hover']}
          items={profiles
            .filter(p => p.id !== profile?.id)
            .map(p => ({
              key: p.id,
              label: i18name(p),
              onClick: () => handleProfileClick(p.id),
            }))}
        >
          <div className="flex items-center cursor-pointer">
            {i18name(profile)} <CaretDownOutlined className="text-sm ml-2" />
          </div>
        </Dropdown>
      ) : (
        <div className="flex items-center cursor-pointer">
          {i18name(profile)}
        </div>
      )}
      {profile.type === 'super-admin' && (
        <div className={`${menuClasses} border-solid border-0 border-r-2`}>
          <HseSwitcher />
        </div>
      )}
      <div className={`${menuClasses} font-bold`}>
        <UserLabel
          id={user?.id}
          label={`${user?.firstname} ${user?.lastname?.toUpperCase()}`}
          withIcon={false}
          withEllipsis={false}
        />
        <Tooltip placement="left" title={i18n('logout')}>
          <LogoutOutlined
            size="1.3rem"
            onClick={handleLogout}
            className="ml-4 text-red-900"
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default Header
